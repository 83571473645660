'use client';

import { getCookie } from 'lowcode/common/ABTestClient/ABTestCookie';
import { baseUrl } from 'lowcode/utils/service/baseUrl';
import getDefaultHeader from 'lowcode/utils/service/getDefaultHeader';

const requestGet = async (url: string, locale: string, token?: any, revalidate?: number) => {
    const headers: any = getDefaultHeader(locale);

    const res = await fetch(url, {
        method: 'GET',
        headers: headers,
        next: { revalidate: revalidate || 0 },
    });

    return res.json();
};

// 发起请求
const requestPost = async (url: string, data: any, locale: string) => {
    const headers: any = getDefaultHeader(locale);
    const token = getCookie('token');
    const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            ...headers,
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        next: { revalidate: 5 },
    });

    return res.json();
};

export const sendFormCommon = async (params: any, locale: any) => {
    const res = await requestPost(`${baseUrl(locale)}/dpuQuote`, params, locale);
    return res;
};

export const statesList = async (countryId: string, locale: any) => {
    const res = await requestGet(`${baseUrl(locale)}/address/statesList?countryId=${countryId}&page=1&size=300`, locale);
    return res;
};
