'use client';

export default function IconCheckbox() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" fill="#3F68E0" stroke="#3F68E0" />
            <path d="M15 6.83984L8.42004 13.4198L5 9.99976" stroke="white" strokeWidth="1.35" strokeLinecap="round" />
        </svg>
    );
}
