'use client';

import { shopifyCardText } from './utils/shopifyCardText';
import { getSaveNum } from './utils/getSaveNum';
import getLocal from '@/app/utils/getLocal';

export default function ShopifyCardSave({
    saveType = 'v1',
    price,
    compareAtPrice,
}: {
    saveType?: string;
    price: number | string | undefined;
    compareAtPrice: number | string | undefined;
}) {
    const { locale } = getLocal();
    const textA = shopifyCardText[locale] || shopifyCardText['us'];
    const offNum = getSaveNum(price, compareAtPrice);
    let saveTypeClassName = 'shopify-save-v1';
    if (saveType === 'v2') {
        saveTypeClassName = 'shopify-save-v2';
    }
    return (
        <div className={saveTypeClassName}>
            <span className="shopify-save-line-a">{offNum}%</span>
            <span className="shopify-save-line-b">{textA.save_off}</span>
        </div>
    );
}
