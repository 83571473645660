'use client';

import { useEffect, useState } from 'react';
import EditorImg from 'lowcode/components/EditorImg';
import EditorLink from 'lowcode/components/EditorLink';
import ShopifyCardSave from 'lowcode/components/shopify/ShopifyCardSave';
import ShopifyOrderTop from 'lowcode/components/shopify/ShopifyOrderTop';
import ShopifyGetPoints from 'lowcode/components/shopify/ShopifyGetPoints';
import ShopifySellProcess from 'lowcode/components/shopify/ShopifySellProcess';
import editorEventsBus from 'lowcode/utils/editorEventsBus';
import { FormatMoneyLocal } from 'lowcode/common/ShopifyCart/const';
import { getIsLogin } from 'lowcode/utils/getIsLogin';
import shopifyCardText, { imgSettingConfig } from './textCard';
import { creditsDayListProducts, getProductUrl } from './apis';
import getLocal from '@/app/utils/getLocal';
import wss from '@/utils/mqtt-client';
import { getWSSUrl } from '@/utils/countryRegion';
import topics from '@/utils/mqtt-client/crowd-funding-topics';
import gtm from '@/utils/gtm';

export default function ClientComp() {
    const { locale } = getLocal();
    const [listData, setListData] = useState([]);
    const textA = shopifyCardText[locale] || shopifyCardText['us'];
    const [disabled, setDisabled] = useState(false);
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        setIsLogin(getIsLogin());
    }, []);

    const { receiver, mqttUnsubscribe, mqttSubscribe } = wss({
        url: String(getWSSUrl(locale)),
        topics,
        locale,
        subscribes: ['seckill'],
    });

    useEffect(() => {
        if (!receiver || !receiver.data) return;
        const { productList, secKillStartTime, secKillEndTime } = receiver.data;
        setListData(productList);
        // update秒杀开始结束时间
        editorEventsBus.emit('flashkill-time', secKillStartTime, secKillEndTime);
    }, [receiver]);

    // 请求单个
    const initFn = async () => {
        const { code, data } = await creditsDayListProducts(locale);
        if (code !== '0') return;

        const { secKillStartTime, secKillEndTime, productList } = data;
        setListData(productList);
        // 秒杀未开始不可点击
        if (Date.now() < secKillStartTime) setDisabled(false);
        // 添加秒杀开始结束时间
        editorEventsBus.emit('flashkill-time', secKillStartTime, secKillEndTime);
    };

    const handleDetail = (item: any, toDetail?: boolean) => {
        if (!isLogin && !toDetail) {
            const pathname2 = location.pathname;
            location.href = `/${locale}/login?redirect=${pathname2}`;
            return;
        }
        if (disabled) return;
        gtm.push({
            event: 'uaEvent',
            event_name: 'buy',
            product_name: item.title,
            position: 'Product Preview',
        });
        getProductUrl(locale, item.id).then((res) => {
            if (res.code !== '0') return;
            // toDetail区分点击标题还是购买
            const data = res?.data || {};
            console.log(data, item);
            if (toDetail) {
                location.href = data.productUrl;
                return;
            }
            // 打开购物车
            editorEventsBus.emit('ShopifyCart-add', {
                ...item,
                productId: data.productId,
                variantId: data.variantId,
                productUrl: data.productUrl,
                purchaseUrl: data.purchaseUrl,
            });
        });
    };

    useEffect(() => {
        initFn();
        mqttSubscribe('seckill');
        return () => mqttUnsubscribe('seckill');
    }, []);

    return (
        <>
            {listData.map((item: any, index: number) => {
                return (
                    <div className="shopify-card-item" key={index}>
                        <div className="shopify-card-item-l">
                            <EditorImg pc={imgSettingConfig[item.id] || item.imageUrl} />
                            <ShopifyCardSave saveType="v2" price={item.price} compareAtPrice={item.compareAtPrice} />
                            {index === 0 && <ShopifyOrderTop topNumber={1} />}
                            <ShopifyGetPoints num={3} points={item.points} desc="ポイント" />
                        </div>
                        <div className="shopify-card-item-r">
                            <ShopifySellProcess num={item.inventoryQuantity} total={item.displayInventoryQuantity} />
                            <div className="shopify-card-item-title">{item.title}</div>
                            <div className="shopify-card-item-price1">
                                <span>{textA.shopify_card_price_text1}</span>
                                <span>{FormatMoneyLocal(item.price, locale)}</span>
                            </div>
                            <div className="shopify-card-item-price2">
                                {textA.shopify_card_price_text1}
                                <span>{FormatMoneyLocal(item.compareAtPrice, locale)}</span>
                            </div>
                            <div className="shopify-card-item-btns">
                                <EditorLink className="shopify-card-item-btn1" onClick={() => handleDetail(item, true)}>
                                    {textA.btn_text1}
                                </EditorLink>
                                <EditorLink className={`shopify-card-item-btn2 ${disabled ? 'disabled' : ''}`} onClick={() => handleDetail(item)}>
                                    {disabled ? '6日から購入可能' : textA.btn_text2}
                                </EditorLink>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
}
