'use client';
import EditorImg from 'lowcode/components/EditorImg';
import EditorLearnMore from 'lowcode/components/EditorLearnMore';
import EditorLink from 'lowcode/components/EditorLink';
import DefaultStyle from 'lowcode/common/DefaultStyle';
import { useState } from 'react';
import styles from './index.module.scss';
import gtm from '@/utils/gtm';

export default function Content({ id, data, attr, initIndex = 0 }: any) {
    const [selIndex, setSelIndex] = useState(initIndex || 0);
    const { title, bgColor, list = [] } = data;

    // 点击切换
    const clickHandle = (index: number, button_name: string) => {
        setSelIndex(index);
        gtm.push({
            event: 'uaEvent',
            event_name: 'tab_click',
            list_name: title,
            button_name,
        });
    };

    return (
        <div
            className={`${styles.content} temp-ProductScenario temp-hide temp-${id}`}
            id={id}
            style={{
                backgroundColor: bgColor,
            }}
        >
            <DefaultStyle id={id} attr={attr} />
            {title && <div className="ProductScenario-title">{title}</div>}
            {list.length > 1 && (
                <div className="ProductScenario-tabh">
                    <div className="ProductScenario-tabh-in">
                        {list.map((item: any, index: number) => (
                            <div
                                className={`ProductScenario-tabh-item ${selIndex === index ? 'ProductScenario-active' : ''}`}
                                onClick={() => clickHandle(index, item.tabname)}
                                key={index}
                            >
                                {item.tabname}
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div className={`ProductScenario-tabc ${list.length > 1 ? 'multi' : 'single'}`}>
                <div className="ProductScenario-tabc-in">
                    {list.map((item: any, index: number) => {
                        const theme = item.theme;
                        return (
                            <div
                                className={`ProductScenario-tabc-item ProductScenario-pos-${item.position} ${
                                    selIndex === index ? 'ProductScenario-active' : ''
                                }`}
                                key={index}
                            >
                                <div className="ProductScenario-imgbox">
                                    <EditorLink
                                        href={item.linkMore}
                                        dataLayer={{
                                            event_name: 'select_product',
                                            product_name: item.title,
                                            list_name: title,
                                            button_name: item.btnMoreText,
                                        }}
                                    >
                                        <EditorImg pc={item.pc} h5={item.h5} />
                                    </EditorLink>
                                    <div className="ProductScenario-msbox">
                                        <div className="ProductScenario-msbox-l">
                                            <div
                                                className="ProductScenario-ms-h"
                                                style={{
                                                    color: theme,
                                                }}
                                            >
                                                {item.title}
                                            </div>
                                            {item.desc && (
                                                <div
                                                    className="ProductScenario-ms-c"
                                                    style={{
                                                        color: theme,
                                                    }}
                                                >
                                                    {item.desc}
                                                </div>
                                            )}
                                        </div>
                                        {item.linkMore && item.btnMoreText && (
                                            <div className="ProductScenario-msbox-r">
                                                <EditorLearnMore
                                                    href={item.linkMore}
                                                    style={{
                                                        color: theme,
                                                        borderColor: theme,
                                                    }}
                                                >
                                                    {item.btnMoreText}
                                                </EditorLearnMore>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
