import GloLayout from 'lowcode/common/GloLayout';
import styles from './index.module.scss';
import ClientComp from './ClientComp';

export default function Content({ id, data, initIndex = 0, isEditStatus = false }: any) {
    const { theme = '' } = data;

    return (
        <GloLayout id={id} className={`temp-tab-sign-a ${styles.content} tab-sign-a-theme-${theme}`}>
            <ClientComp id={id} initIndex={initIndex} data={data} />
        </GloLayout>
    );
}
