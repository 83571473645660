'use client';
import EditorImg from 'lowcode/components/EditorImg';
import { useEffect, useRef, useState } from 'react';

export default function Content({ id, isEditStatus, data }: any) {
    const { theme = '', title = '', pc = '', h5 = '' } = data;

    const [isEnter, setIsEnter] = useState(isEditStatus);
    const isEnterRef = useRef(isEditStatus);
    useEffect(() => {
        const elem = document.getElementById(`${id}`);
        if (elem) {
            window.addEventListener('scroll', () => {
                const clientH = document.documentElement.clientHeight;
                const { top } = elem.getBoundingClientRect();
                const hIsEnter = top < clientH * 0.5 - 150; //距离已进入

                if (!isEnterRef.current && hIsEnter) {
                    isEnterRef.current = true;
                    setIsEnter(true);
                } else if (isEnterRef.current && !hIsEnter) {
                    isEnterRef.current = false;
                    setIsEnter(false);
                }
            });
        }
    }, []);

    return (
        <div className={`sticky-content${isEnter ? ' isEnter' : ''} sticky-one-a-theme-${theme}`}>
            <EditorImg pc={pc} h5={h5} />
            <div className="sticky-one-a-mask"></div>
            <div className="sticky-one-a-info">
                <h2>{title}</h2>
            </div>
        </div>
    );
}
