'use client';
import { useEffect, useState } from 'react';
import FormErrorTips from '../comps/FormErrorTips';
import { statesList } from '../sendApis';
import getLocal from '@/app/utils/getLocal';

export default function FormSelect({ id, data, index, callback }: any) {
    const { locale } = getLocal();
    const [showError, setShowError] = useState(false); //是否显示错误
    const [showList, setShowList] = useState(false); //是否显示列表
    const [selectList, setSelectList] = useState(data.selectList || []);
    const [selectLabel, setSelectLabel] = useState('');
    // 切换列表显隐
    const toggleHandle = () => {
        const showList2 = !showList;
        setShowList(showList2);
    };
    // 选中
    const changeHandle = (item: any) => {
        const val = item.value;
        setSelectLabel(item.label);
        callback(data, index, val);
        if (data.isRequire && !val) {
            setShowError(true);
        } else {
            setShowError(false);
        }
        setShowList(false);
    };

    useEffect(() => {
        const initList = async () => {
            let countryId = '233';
            if (locale === 'us') {
                countryId = '233';
            } else if (locale === 'ca') {
                countryId = '39';
            }
            const res = await statesList(countryId, locale);
            if (res.code === '0') {
                const dataA = res?.data?.content || [];

                setSelectList(
                    dataA.map((item: any) => {
                        return {
                            label: item.name,
                            value: item.id,
                        };
                    }),
                );
            }
        };
        if (data.isSelectInterface) {
            initList();
        }
    }, []);

    return (
        <div className={`form-common-content form-common-select${showList ? ' form-common-select-active' : ''}${showError ? ' form-common-show-error' : ''}`}>
            <div className={`form-common-select-input-box`} onClick={toggleHandle}>
                <input className="form-common-select-input" readOnly placeholder={data.placeholder} value={selectLabel} onChange={changeHandle} />
                <svg className="form-common-select-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                        d="M13.6406 6.25651L8.30729 11.5898L2.97396 6.25651"
                        stroke="#949494"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>

            <div className="form-common-select-list scrollbar-editor-black">
                {selectList.map((item: any, index: number) => {
                    return (
                        <div
                            className="form-common-select-item"
                            key={index}
                            onClick={() => {
                                changeHandle(item);
                            }}
                        >
                            {item.label}
                        </div>
                    );
                })}
            </div>

            <FormErrorTips data={data} />
        </div>
    );
}
