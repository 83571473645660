'use client';
import { useState } from 'react';
import FormErrorTips from '../comps/FormErrorTips';
import IconCheckbox from '../comps/IconCheckbox';
export default function FormRadio({ id, data, index, callback }: any) {
    const [showError, setShowError] = useState(false);
    const [selRadio, setSelRadio] = useState(data.itemVal || '');
    const changeHandle = (val: string) => {
        setSelRadio(val);
        setShowError(false);
        callback(data, index, val);
    };

    return (
        <div className={`form-common-content form-common-radio ${showError ? ' form-common-show-error' : ''}`}>
            <div className="form-common-radio-title">{data.placeholder}</div>
            <div className="form-common-radio-list">
                <div
                    className={`form-common-radio-item ${selRadio === 'Y' ? ' form-common-radio-item-check' : ''}`}
                    onClick={() => {
                        changeHandle('Y');
                    }}
                >
                    <span className="form-common-radio-input">
                        <IconCheckbox />
                    </span>
                    <span className="form-common-radio-label">{data.radioY}</span>
                </div>
                <div
                    className={`form-common-radio-item ${selRadio === 'N' ? ' form-common-radio-item-check' : ''}`}
                    onClick={() => {
                        changeHandle('N');
                    }}
                >
                    <span className="form-common-radio-input">
                        <IconCheckbox />
                    </span>
                    <span className="form-common-radio-label">{data.radioN}</span>
                </div>
            </div>
            <FormErrorTips data={data} />
        </div>
    );
}
