'use client';
import EditorImg from 'lowcode/components/EditorImg';
import EditorLink from 'lowcode/components/EditorLink';
import EditorVideo from 'lowcode/components/EditorVideo';
import { useEffect, useRef, useState } from 'react';

export default function Content({ id, isEditStatus, data }: any) {
    const { theme = '', title1 = '', title2 = '', title3 = '', isVideo, pc, h5, moreLink1, moreText1 } = data;
    const hasUrl = pc || h5; //是否有图片或视频
    const [isEnter, setIsEnter] = useState(isEditStatus);
    const isEnterRef = useRef(isEditStatus);
    useEffect(() => {
        const elem = document.getElementById(`${id}`);
        if (elem) {
            const isEnterFn = () => {
                const clientH = document.documentElement.clientHeight;
                const { top } = elem.getBoundingClientRect();
                const hEnter = top < clientH - 150; //距离已进入

                if (!isEnterRef.current && hEnter) {
                    isEnterRef.current = true;
                    setIsEnter(true);
                } else if (isEnterRef.current && !hEnter) {
                    isEnterRef.current = false;
                    setIsEnter(false);
                }
            };
            isEnterFn(); //执行1次
            window.addEventListener('scroll', isEnterFn);
        }
    }, []);
    return (
        <div className={`product-scene-a-in${isEnter ? ' isEnter' : ''} product-scene-a-theme-${theme}`}>
            <div className="product-scene-a-info">
                {title1 && <p className="product-scene-a-title1">{title1}</p>}
                {title2 && <h2 className="product-scene-a-title2">{title2}</h2>}
                {title3 && (
                    <div
                        className="product-scene-a-title3"
                        dangerouslySetInnerHTML={{
                            __html: title3,
                        }}
                    ></div>
                )}
                {moreLink1 && moreText1 && (
                    <EditorLink className="product-scene-a-more" href={moreLink1}>
                        {moreText1}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path
                                d="M6.25651 2.35889L11.5898 7.69222L6.25651 13.0256"
                                stroke="#3F68E0"
                                strokeWidth="1.4"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            ></path>
                        </svg>
                    </EditorLink>
                )}
            </div>
            {hasUrl && (
                <div className="product-scene-a-img">
                    {!isVideo && <EditorImg pc={pc} h5={h5} />}
                    {isVideo && <EditorVideo pc={pc} h5={h5} />}
                </div>
            )}
        </div>
    );
}
