'use client';
import EditorImg from 'lowcode/components/EditorImg';
import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function Content({ data }: any) {
    const { list = [] } = data;
    const [openList, setOpenList] = useState<number[]>([]);

    // 切换事件
    const toggleHandle = (index: number) => {
        const openList2 = JSON.parse(JSON.stringify(openList));
        if (openList.includes(index)) {
            const index2 = openList.indexOf(index);
            openList2.splice(index2, 1);
        } else {
            openList2.push(index);
        }

        setOpenList(openList2);
    };

    return (
        <Swiper className="swiper-list-a-list" spaceBetween={20} slidesPerView={'auto'}>
            {list.map((item: any, index: number) => {
                return (
                    <SwiperSlide key={index}>
                        <div className="swiper-list-a-card-img">
                            <EditorImg pc={item.pc} h5={item.h5} />
                        </div>
                        <div className="swiper-list-a-card-title">{item.title}</div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}
