'use client';
import EditorImg from 'lowcode/components/EditorImg';
import EditorLink from 'lowcode/components/EditorLink';
import DefaultStyle from 'lowcode/common/DefaultStyle';
import { useState, useRef } from 'react';
import styles from './index.module.scss';
import gtm from '@/utils/gtm';

const RightArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M6 4L10 8L6 12" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default function Content({ id, data, attr, initIndex = 0, isEditStatus }: any) {
    const [selIndex, setSelIndex] = useState(initIndex || 0);
    const { title, bgColor, list = [] } = data;

    const containerRef = useRef<any>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startScrollLeft, setStartScrollLeft] = useState(0);

    // 点击切换
    const clickHandle = (index: number, button_name: string) => {
        setSelIndex(index);
        gtm.push({
            event: 'uaEvent',
            event_name: 'tab_click',
            list_name: title,
            button_name,
        });
    };

    const handleBuyGtm = (data: any) => {
        gtm.push(data);
    };

    const getListClassNames = (list: any[]) => {
        if (list.length === 3) return 'round-3';
        if (list.length === 4) return 'round-4';
        if (list.length === 5) return 'round-5';
        if (list.length === 6) return 'round-6';
        return '';
    };

    // 鼠标按下事件处理
    const handleMouseDown = (e: any) => {
        setIsDragging(true);
        setStartX(e.pageX - containerRef.current.offsetLeft);
        setStartScrollLeft(containerRef.current.scrollLeft);
    };

    // 鼠标移动事件处理
    const handleMouseMove = (e: any) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - containerRef.current.offsetLeft;
        const walk = (x - startX) * 2; // 调整滑动速度系数
        containerRef.current.scrollLeft = startScrollLeft - walk;
    };

    // 清理拖动状态
    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // 防止图片拖拽冲突
    const handleDragStart = (e: any) => {
        e.preventDefault();
    };

    const testImg = 'https://websiteoss.ecoflow.com/upload/image/1739349920936/m_Frame%20161269456%20(1).png';

    return (
        <div
            className={`${styles.content} temp-ProductScenario temp-hide temp-${id}`}
            id={id}
            style={{
                backgroundColor: bgColor,
            }}
        >
            <DefaultStyle id={id} attr={attr} />
            {title && <div className="ProductScenario-title">{title}</div>}
            <div
                ref={containerRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                className={`ProductScenarioSlide-content ${getListClassNames(list)}`}
            >
                {list.map((item: any, ind: number) => (
                    <div
                        key={ind}
                        onDragStart={handleDragStart}
                        className={`ProductScenarioSlide-item ${selIndex === ind && isEditStatus ? 'ProductScenario-item-active' : ''}`}
                    >
                        <EditorImg pc={item.pc || testImg} h5={item.h5 || testImg} />
                        <div className="ProductScenarioSlide-item-text">
                            <div className="ProductScenarioSlide-item-title">{item?.subtitle}</div>
                            <div className="ProductScenarioSlide-item-desc">{item?.desc}</div>
                            <div className="ProductScenarioSlide-item-btns">
                                <EditorLink
                                    onClick={() =>
                                        handleBuyGtm({
                                            event: 'uaEvent',
                                            event_name: 'select_product',
                                            product_name: item?.subtitle,
                                            list_name: title,
                                            button_name: item?.btnMoreText,
                                        })
                                    }
                                    href={item?.linkMore || '#'}
                                    className="ProductScenarioSlide-item-btn1"
                                >
                                    {item?.btnMoreText}
                                </EditorLink>
                                {item?.btnBuyText && (
                                    <EditorLink
                                        onClick={() =>
                                            handleBuyGtm({
                                                event: 'uaEvent',
                                                event_name: 'select_product',
                                                product_name: item?.subtitle,
                                                list_name: title,
                                                button_name: item?.btnBuyText,
                                            })
                                        }
                                        href={item?.linkBuyNow || '#'}
                                        className="ProductScenarioSlide-item-btn2"
                                    >
                                        {item?.btnBuyText}
                                        <RightArrow />
                                    </EditorLink>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
