'use client';
import { useState } from 'react';
import FormErrorTips from '../comps/FormErrorTips';
import IconCheckbox from '../comps/IconCheckbox';

export default function FormCheckbox({ id, data, index, callback }: any) {
    const [showError, setShowError] = useState(false);
    const [selectList, setSelectList] = useState(data.selectList || []);
    const [activeIndexs, setActiveIndexs] = useState<number[]>([]);
    const changeHandle = (itemIndex: number) => {
        const activeIndexs2 = JSON.parse(JSON.stringify(activeIndexs));
        const indexB = activeIndexs2.indexOf(itemIndex); //新元素在选中的下标
        if (indexB > -1) {
            activeIndexs2.splice(indexB, 1);
        } else {
            activeIndexs2.push(itemIndex);
        }
        setActiveIndexs(activeIndexs2);
        callback(data, index, activeIndexs2); //回调到父级
        //判断是否显示错误提示
        if (data.isRequire && activeIndexs2.length === 0) {
            setShowError(true);
        } else {
            setShowError(false);
        }
    };

    return (
        <div className={`form-common-content form-common-radio form-common-checkbox ${showError ? ' form-common-show-error' : ''}`}>
            <div className="form-common-radio-title">{data.placeholder}</div>
            <div className="form-common-radio-list">
                {selectList.map((item: any, index: number) => {
                    return (
                        <div
                            className={`form-common-radio-item ${activeIndexs.includes(index) ? ' form-common-radio-item-check' : ''}`}
                            key={index}
                            onClick={() => {
                                changeHandle(index);
                            }}
                        >
                            <span className="form-common-radio-input">
                                <IconCheckbox />
                            </span>
                            <span className="form-common-radio-label">{item.label}</span>
                        </div>
                    );
                })}
            </div>
            <FormErrorTips data={data} />
        </div>
    );
}
