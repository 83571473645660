import GloLayout from 'lowcode/common/GloLayout';
import EditorImg from 'lowcode/components/EditorImg';
import styles from './index.module.scss';
import ClientCompItem from './ClientCompItem';

export default function Content({ id, data, initIndex = 0, isEditStatus = false }: any) {
    const { list = [] } = data;

    return (
        <GloLayout id={id} className={`temp-video-list-v1 temp-hide ${styles.content}`}>
            <div className="video-list-v1-in">
                {list.map((item: any, index: number) => {
                    return (
                        <div className="video-list-v1-item" key={index}>
                            <div className="video-list-v1-img">
                                <EditorImg pc={item.pc} />
                                <ClientCompItem item={item} />
                            </div>
                            <div className="video-list-v1-title">{item.title}</div>
                        </div>
                    );
                })}
            </div>
        </GloLayout>
    );
}
