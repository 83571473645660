'use client';
import EditorLink from 'lowcode/components/EditorLink';
import styles from './index.module.scss';

export default function EditorLearnMore({ className, children, ...rest }: any) {
    return (
        <EditorLink className={styles.lowcodelearnmore} {...rest}>
            {children}
        </EditorLink>
    );
}
