'use client';

export default function StyleComp({ id, data = {} }: any) {
    const { topNum, bgColor, itemHoverBgColor, itemHoverFontColor } = data;
    return (
        <>
            <style>
                {`
                .temp-${id} {
                    top: ${topNum}px;
                    background-color: ${bgColor};
                }
                .temp-${id} .bar-anchor-item.bar-anchor-item-hover {
                    background: ${itemHoverBgColor};
                    color: ${itemHoverFontColor};
                }
                @media (min-width: 1025px) {
                    .temp-${id} .bar-anchor-item:hover {
                        background: ${itemHoverBgColor};
                        color: ${itemHoverFontColor};
                    }
                }
            `}
            </style>
        </>
    );
}
