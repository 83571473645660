'use client';
import gtm from '@/utils/gtm';
import EditorImgVideo from 'lowcode/components/EditorImgVideo';
import EditorLink from 'lowcode/components/EditorLink';
import { useState } from 'react';

export default function List({ list = [], initIndex = 0, title }: any) {
    const [showItem, setShowItem] = useState<any>(list[initIndex || 0] || {});
    const [showIndex, setShowIndex] = useState(initIndex || 0);
    const handle = (item: any, index: any) => {
        setShowIndex(index);
        setShowItem(item);
        gtm.push({
            event: 'uaEvent',
            event_name: 'select_blog',
            list_name: title,
            blog_name: item.title,
            position: 'Product Preview',
        });
    };
    return (
        <>
            <div className="JoinUs-in JoinUs-pc">
                <div className="JoinUs-l">
                    <EditorImgVideo isVideo={showItem.isVideo} pc={showItem.pc} autoPlay={true} loop={true} />
                </div>
                <div className="JoinUs-r">
                    {list?.map((item: any, index: number) => {
                        const { title: blog_title, desc, link, linkText } = item;
                        return (
                            <div className={`JoinUs-item ${showIndex === index ? 'JoinUs-active' : ''}`} key={index}>
                                <div className="JoinUs-item-title" onMouseEnter={() => handle(item, index)}>
                                    <div className="JoinUs-item-title-in">{blog_title}</div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 25" fill="none">
                                        <path
                                            d="M4.30762 14.9615L12.3076 6.96146L20.3076 14.9615"
                                            stroke="black"
                                            strokeWidth="2.28046"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div className="JoinUs-item-box">
                                    <div className="JoinUs-item-desc">{desc}</div>
                                    {linkText && link && (
                                        <EditorLink
                                            href={link}
                                            className="JoinUs-item-link"
                                            dataLayer={{
                                                event_name: 'select_blog',
                                                page_name: link,
                                                list_name: title,
                                                blog_name: blog_title,
                                                button_name: linkText,
                                            }}
                                        >
                                            {linkText}
                                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path
                                                        id="Path-2"
                                                        d="M5.08317 2.13321L9.4165 6.46654L5.08317 10.7999"
                                                        stroke="#3F68E0"
                                                        strokeWidth="1.1375"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </g>
                                            </svg>
                                        </EditorLink>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
