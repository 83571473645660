'use client';
import EditorImg from 'lowcode/components/EditorImg';
import EditorVideo from 'lowcode/components/EditorVideo';
import styles from './index.module.scss';

export default function EditorImgVideo({
    isVideo = false,
    pc,
    h5,
    autoPlay,
    loop = false,
    poster,
    posterH5,
    alt,
}: {
    isVideo?: boolean;
    pc?: string;
    h5?: string;
    autoPlay?: boolean;
    loop?: boolean;
    poster?: string;
    posterH5?: string;
    alt?: string;
}) {
    return (
        <>
            {!isVideo && <EditorImg pc={pc} h5={h5} alt={alt} />}
            {isVideo && pc && <EditorVideo className={`${styles.imgvideoPc}`} autoPlay={autoPlay} loop={loop} src={pc} poster={poster} posterH5={posterH5} />}
        </>
    );
}
