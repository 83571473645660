'use cilent';
export const getSaveNum = (price: number | string | undefined, compareAtPrice: number | string | undefined) => {
    if (price && compareAtPrice) {
        const compareAtPrice2 = parseFloat(`${compareAtPrice}`);
        const price2 = parseFloat(`${price}`);
        const num2 = compareAtPrice2 - price2;
        return ((num2 * 100) / compareAtPrice2).toFixed();
    }
    return 0;
};
