'use client';

export default function FormErrorTips({ data }: any) {
    const emptyTips = data?.emptyTips || '';
    return (
        <div className="form-common-error">
            {emptyTips && (
                <div className="form-common-error-line">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <g clipPath="url(#clip0_8201_3134)">
                            <path
                                d="M6.14966 3L6.46258 7.78185H7.53742L7.85034 3H6.14966ZM6.9932 8.94459C6.72109 8.94459 6.4898 9.0285 6.29932 9.22431C6.09524 9.40613 6 9.64389 6 9.9376C6 10.2173 6.09524 10.4551 6.29932 10.6509C6.4898 10.8467 6.72109 10.9446 6.9932 10.9446C7.26531 10.9446 7.5102 10.8467 7.71429 10.6649C7.90476 10.4691 8 10.2313 8 9.9376C8 9.64389 7.90476 9.40613 7.71429 9.22431C7.52381 9.0285 7.27891 8.94459 6.9932 8.94459Z"
                                fill="#F8200C"
                            />
                            <path
                                d="M7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5Z"
                                stroke="#F8200C"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_8201_3134">
                                <rect width="14" height="14" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span>{emptyTips}</span>
                </div>
            )}
        </div>
    );
}
