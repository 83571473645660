'use client';

import { fetchPost } from 'lowcode/utils/service/FetchClient';
import { loginUrl } from 'lowcode/utils/service/baseUrl';

export const creditsDayListProducts = async (locale: any) => {
    const res = await fetchPost(
        `${loginUrl(locale)}/shopify/web/creditsDay/listProducts`,
        locale,
        {
            module: 'MORMAL',
        },
        {
            needToken: true,
        },
    );
    return res;
};
