'use client';

import { numberToThousands } from 'lowcode/common/shopify/numberToThousands';
import { shopifyCardText } from '../utils/shopifyCardText';
import IconA from './IconA';
import getLocal from '@/app/utils/getLocal';

export default function ShopifyGetPoints({ num = 1, points = 0, desc }: { num?: number; points: number; desc?: string | undefined }) {
    const { locale } = getLocal();
    const textA = shopifyCardText[locale] || shopifyCardText['us'];
    return (
        <div className="shopify-get-points">
            <IconA />
            <div className="shopify-get-points-text-a">
                {num}
                {textA.get_points}
            </div>
            <div className="shopify-get-points-text-b">
                {numberToThousands(points * 1, locale)}
                {desc}
            </div>
        </div>
    );
}
