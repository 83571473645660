import GloLayout from 'lowcode/common/GloLayout';
import styles from './index.module.scss';
import EditorLink from 'lowcode/components/EditorLink';
import EditorIcon from 'lowcode/components/EditorIcon';

export default function Content({ id, data, initIndex = 0, isEditStatus = false }: any) {
    const { list = [] } = data;

    return (
        <GloLayout id={id} className={`temp-nav-icon temp-hide ${styles.content}`}>
            {list.map((item: any, index: number) => {
                return (
                    <EditorLink className="nav-icon-item" href={item.href} key={index}>
                        <div className="nav-icon-img">
                            <EditorIcon src={item.src} />
                        </div>
                        <div className="nav-icon-title">{item.title}</div>
                    </EditorLink>
                );
            })}
        </GloLayout>
    );
}
