'use client';
import EditorIcon from 'lowcode/components/EditorIcon';
import { FC } from 'react';
import styles from './index.module.scss';

const Content: FC<{ id: string; attr: any; data: any }> = ({ id, attr, data }) => {
    const { title, titleColor, desc, descColor, list = [], theme } = data;
    let theme2 = theme;
    if (theme === 'gray') {
        theme2 = '#f4f4f4';
    }
    const compStyle: any = {
        '--ServiceModule-theme': theme2,
        '--ServiceModule-title': titleColor,
        '--ServiceModule-desc': descColor,
    };
    return (
        <section className={`${styles.content} temp-ServiceModule temp-hide temp-${id}`} id={id} style={compStyle}>
            {title && <div className="service-module-box-title">{title}</div>}
            {desc && <div className="service-module-box-desc">{desc}</div>}
            <ul className={`service-module-box`}>
                {list.map((item: any, k: number) => (
                    <li className="item" key={k}>
                        {item?.icon && (
                            <div className="icon">
                                <EditorIcon pc={item?.icon} alt={item?.title} />
                            </div>
                        )}
                        {item?.title && <h5 className="title">{item?.title}</h5>}
                        {item?.desc && <p className="desc">{item?.desc}</p>}
                        {item?.link && item?.linkText && (
                            <a href={item?.link} target={String(item?.link)?.startsWith('http') ? '_blank' : '_self'} className="link" rel="noreferrer">
                                <span className="link-text">{item?.linkText}</span>
                                <span className="link-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                                        <path
                                            d="M5.58317 1.9165L9.9165 6.24984L5.58317 10.5832"
                                            stroke="#3F68E0"
                                            strokeWidth="1.1375"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                            </a>
                        )}
                    </li>
                ))}
            </ul>
        </section>
    );
};

export default Content;
