'use client';
import DefaultStyle from 'lowcode/common/DefaultStyle';
import ListH5 from './ListH5';
import ListPc from './ListPc';
import styles from './index.module.scss';

export default function Content({ id, data, attr = {}, initIndex = 0 }: any) {
    const { title = '', list = [] } = data;
    const trimTitle = title.trim();
    return (
        <div className={`${styles.content} temp-JoinUs temp-hide temp-${id} temp-JoinUs-length-${list.length}`} id={id}>
            <DefaultStyle id={id} attr={attr} />
            {trimTitle && <div className="JoinUs-title">{trimTitle}</div>}
            <ListPc list={list} initIndex={initIndex} title={trimTitle} />
            <ListH5 list={list} initIndex={initIndex} title={trimTitle} />
        </div>
    );
}
