'use cilent';
export const shopifyCardText: any = {
    us: {
        buy_now: 'Buy Now',
        learn_more: 'Learn More',
        member_only: 'Member-only',
        non_member_price: '(Non-member price)',
        save_off: 'Off',
        save_r: 'R',
    },
};
