import FormCommon from 'lowcode/common/FormCommon';
import styles from './index.module.scss';

export default function Content({ id, data = {}, attr = {} }: any) {
    return (
        <div className={`${styles.content} form-common-v1 temp-hide temp-${id} form-common-v1-theme-${data.theme}`}>
            <FormCommon id={id} data={data} />
        </div>
    );
}
