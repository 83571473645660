'use client';
import { useState } from 'react';
import FormErrorTips from '../comps/FormErrorTips';

export default function FormInput({ id, data, index, callback }: any) {
    const [showError, setShowError] = useState(false);
    const changeHandle = (e: any) => {
        const val = e.target.value;
        callback(data, index, val);
        if (data.isRequire && !val) {
            setShowError(true);
        } else {
            setShowError(false);
        }
    };
    return (
        <div className={`form-common-content ${showError ? ' form-common-show-error' : ''}`}>
            <input className="form-common-input" placeholder={data.placeholder} value={data.itemVal} onChange={changeHandle} maxLength={data.maxLen || 200} />
            <FormErrorTips data={data} />
        </div>
    );
}
