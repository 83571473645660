import GloLayout from 'lowcode/common/GloLayout';
import styles from './index.module.scss';
import ClientComp from './comps/ClientComp';

export default function Content({ id, data, initIndex = 0, isEditStatus = false }: any) {
    return (
        <GloLayout id={id} className={`temp-shopify-card temp-hide ${styles.content}`}>
            <div className="shopify-card-in">
                <ClientComp />
            </div>
        </GloLayout>
    );
}
