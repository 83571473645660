'use client';
import EditorImg from 'lowcode/components/EditorImg';
import EditorLink from 'lowcode/components/EditorLink';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function Content({ data }: any) {
    const { list = [] } = data;

    return (
        <Swiper className="product-accessories-list" spaceBetween={20} slidesPerView={'auto'}>
            {list.map((item: any, index: number) => {
                return (
                    <SwiperSlide key={index}>
                        <div className="product-accessories-card-img">
                            <EditorImg pc={item.pc} />
                        </div>
                        <div className="product-accessories-card-title">{item.title}</div>
                        <div className="product-accessories-card-btn">
                            <EditorLink href={item.moreLink}>{item.moreText}</EditorLink>
                        </div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}
