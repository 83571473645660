'use client';

import { useEffect, useState } from 'react';
import EditorImg from 'lowcode/components/EditorImg';
import EditorLink from 'lowcode/components/EditorLink';
import ShopifyCardSave from 'lowcode/components/shopify/ShopifyCardSave';
import ShopifyGetPoints from 'lowcode/components/shopify/ShopifyGetPoints';
import editorEventsBus from 'lowcode/utils/editorEventsBus';
import { FormatMoneyLocal } from 'lowcode/common/ShopifyCart/const';
import { getIsLogin } from 'lowcode/utils/getIsLogin';
import shopifyCardText, { imgSettingConfig } from './textCard';
import { creditsDayListProducts } from './apis';
import getLocal from '@/app/utils/getLocal';

type ClientCompProps = {
    module: string;
};

export default function ClientComp({ module }: ClientCompProps) {
    const { locale } = getLocal();
    const [listData, setListData] = useState([]);
    const textA = shopifyCardText[locale] || shopifyCardText['us'];
    const [isLogin, setIsLogin] = useState(false);

    // 请求单个
    const initFn = async () => {
        const data1 = await creditsDayListProducts(locale, module);

        if (data1.code === '0') {
            setListData(data1.data);
        }
    };

    const handleAddCard = (item: any) => {
        if (!isLogin) {
            const pathname2 = location.pathname;
            location.href = `/${locale}/login?redirect=${pathname2}`;
            return;
        }
        // 打开购物车
        editorEventsBus.emit('ShopifyCart-add', {
            ...item,
            productId: item.productId,
            variantId: item.variantId,
            productUrl: item.productUrl,
            purchaseUrl: item.purchaseUrl,
        });
    };

    useEffect(() => {
        setIsLogin(getIsLogin());
        initFn();
    }, []);
    return (
        <>
            {listData.map((item: any, index: number) => {
                return (
                    <div className="shopify-card-item" key={index}>
                        <div className="shopify-card-item-l">
                            <EditorImg pc={imgSettingConfig[item.variantId] || item.imageUrl} />
                            <ShopifyCardSave saveType="v2" price={item.price} compareAtPrice={item.compareAtPrice} />
                            <ShopifyGetPoints num={2} points={item.points} desc="ポイント" />
                        </div>
                        <div className="shopify-card-item-r">
                            <div className="shopify-card-item-title">{item.title}</div>
                            <div className="shopify-card-item-price1">
                                <span>{textA.shopify_card_price_text1}</span>
                                <span>{FormatMoneyLocal(item.price, locale)}</span>
                            </div>
                            <div className="shopify-card-item-price2">
                                {textA.shopify_card_price_text1}
                                <span>{FormatMoneyLocal(item.compareAtPrice, locale)}</span>
                            </div>
                            <div className="shopify-card-item-btns">
                                <EditorLink className="shopify-card-item-btn1" href={item.productUrl}>
                                    {textA.btn_text1}
                                </EditorLink>
                                <EditorLink className="shopify-card-item-btn2" onClick={() => handleAddCard(item)}>
                                    {textA.btn_text2}
                                </EditorLink>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
}
