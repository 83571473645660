'use client';
import { useState } from 'react';
import FormEmail from './FormEmail';
import FormInput from './FormInput';
import FormTextarea from './FormTextarea';
import FormSelect from './FormSelect';
import FormRadio from './FormRadio';
import FormCheckbox from './FormCheckbox';
import FormPrivacy from './comps/FormPrivacy';
import FormSubscribe from './comps/FormSubscribe';
import FormRecaptcha from './comps/FormRecaptcha';
import styles from './index.module.scss';
import { sendFormCommon } from './sendApis';
import getLocal from '@/app/utils/getLocal';

export default function FormCommon({ id, data, callback }: any) {
    const { theme, isPrivacy = true, isSubscribe = true, isRecaptcha = false, list = [] } = data;
    const { locale } = getLocal();
    const [listA, setListA] = useState(list);
    const [disabledSub, setDisabledSub] = useState(true); //置灰提交按钮
    const [privacyFlag, setPrivacyFlag] = useState(true); //是否已点击隐私协议
    const [subscribeFlag, setSubscribeFlag] = useState(true); //是否已点击订阅
    const [recaptchaVal, setRecaptchaVal] = useState(''); //人机验证的值
    const [isOpenCheck, setIsOpenCheck] = useState(false);

    // 单项值变化回调
    const itemCallback = (item: any, index: number, value: any) => {
        const listA2 = JSON.parse(JSON.stringify(listA));
        const item2 = listA2[index] || {};
        item2.itemVal = value;

        setListA(listA2);
        // 是否置灰
        getVerifFromList(listA2);
    };
    // 是否置灰
    const getVerifFromList = (list: any, options?: any) => {
        let flag = true; //默认通过
        list.forEach((item: any) => {
            if (item.isRequire && !item.itemVal) {
                flag = false;
            }
        });

        // 配置了隐私协议,需要同意
        if (isPrivacy && !privacyFlag) {
            flag = false;
        }
        // 人机验证
        const options2 = options || {};
        const recaptchaVal2 = options2.recaptchaVal || recaptchaVal;

        if (isRecaptcha && !recaptchaVal2) {
            flag = false;
        }

        setDisabledSub(!flag); //设置是否置灰
        return flag;
    };
    // 从 list 中获取发送数据库的值
    const getParamsFromList = (list: any) => {
        const params: any = {};
        list.forEach((item: any) => {
            const itemKey = item.itemKey;
            if (item.formType === 'radio') {
                params[itemKey] = item.itemVal === 'Y' ? true : false;
            } else if (itemKey) {
                params[itemKey] = item.itemVal;
            }
        });
        // 人机验证
        if (isRecaptcha) {
            params.recaptchaResponse = recaptchaVal;
        }

        return params;
    };
    // 从 list 中取出邮箱并订阅
    const getEmailFromList = (list: any) => {
        let email = '';
        list.forEach((item: any) => {
            if (item.formType === 'email') {
                email = item.itemVal;
            }
        });
        return email;
    };
    // 提交
    const submitHandle = async () => {
        const flag = getVerifFromList(listA);
        if (flag) {
            const params = getParamsFromList(listA);
            const res = await sendFormCommon(params, locale);
            if (res.code === '0') {
                const email: string = getEmailFromList(listA);
                window.open(
                    `https://media.ecoflow.com/u/register.php?CID=1049983726&f=1107&p=2&a=r&SID=&el=&llid=&counted=&c=&optin=y&inp_3=${email.trim()}&inp_4423=1&inp_7715=11&inp_7979=13`,
                    '_blank',
                );
                callback && callback();
            } else {
                console.log('fail');
            }
        } else {
            setIsOpenCheck(true);
        }
    };
    // 隐私协议回调
    const privacyCallback = (flag: boolean) => {
        setPrivacyFlag(flag);
        getVerifFromList(listA);
    };
    // 邮箱订阅回调
    const subscribeCallback = (flag: boolean) => {
        setSubscribeFlag(flag);
    };
    // 人机验证回调
    const recaptchaCallback = (val: string) => {
        setRecaptchaVal(val);
        getVerifFromList(listA, {
            recaptchaVal: val,
        });
    };
    return (
        <div className={`${styles.FormCommon} from-common-theme-${theme}`}>
            {listA.map((item: any, index: number) => {
                return (
                    <div
                        key={index}
                        className={`form-common-item${isOpenCheck && item.isRequire && !item.itemVal ? ' form-common-show-error' : ''} form-common-${
                            item.formWidth
                        }`}
                    >
                        {item.formType === 'email' && <FormEmail id={id} data={item} index={index} callback={itemCallback} />}
                        {item.formType === 'input' && <FormInput id={id} data={item} index={index} callback={itemCallback} />}
                        {item.formType === 'textarea' && <FormTextarea id={id} data={item} index={index} callback={itemCallback} />}
                        {item.formType === 'select' && <FormSelect id={id} data={item} index={index} callback={itemCallback} />}
                        {item.formType === 'radio' && <FormRadio id={id} data={item} index={index} callback={itemCallback} />}
                        {item.formType === 'checkbox' && <FormCheckbox id={id} data={item} index={index} callback={itemCallback} />}
                    </div>
                );
            })}
            {isPrivacy && <FormPrivacy callback={privacyCallback} />}
            {isSubscribe && <FormSubscribe callback={subscribeCallback} />}
            {isRecaptcha && <FormRecaptcha callback={recaptchaCallback} />}
            <div className="form-common-submit-box">
                <div className={`form-common-submit-btn ${disabledSub ? ' form-common-submit-disabled' : ''}`} onClick={submitHandle}>
                    Submit
                </div>
            </div>
        </div>
    );
}
