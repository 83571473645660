'use cilent';
export const shopifyCardText: any = {
    us: {
        buy_now: 'Buy Now',
        learn_more: 'Learn More',
        member_only: 'Member-only',
        non_member_price: '(Non-member price)',
        save_off: 'OFF',
        save_r: 'R',
        order_top_num1: '人気',
        order_top_num2: 'No.',
        get_points: '倍',
        sell_process_l: '残り',
        sell_process_r: '台',
    },
};
