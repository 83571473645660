'use client';

import { fetchPost } from 'lowcode/utils/service/FetchClient';
import { loginUrl } from 'lowcode/utils/service/baseUrl';

export const creditsDayListProducts = async (locale: string) => {
    const res = await fetchPost(
        `${loginUrl(locale)}/shopify/web/creditsDay/listSecKillProducts`,
        locale,
        {},
        {
            needToken: true,
        },
    );
    return res;
};

export const getProductUrl = async (locale: string, id: string) => {
    const res = await fetchPost(`${loginUrl(locale)}/shopify/web/creditsDay/getSecKillProductUrl`, locale, { id }, { needToken: true });
    return res;
};
