export type TypeOurStory = {
    theme: string;
    position: string;
    title: string;
    content: string;
    isVideo: boolean;
    pc: any;
    h5?: any;
    btn?: string;
    link?: string;
};

export const DEFAULT_DATA: TypeOurStory = {
    theme: 'white',
    position: 'left',
    title: 'Our Story',
    content: '',
    isVideo: false,
    pc: '',
    h5: '',
    btn: 'Learn more about EcoFlow',
    link: '/',
};

export const config = {
    defaultData: DEFAULT_DATA,
    defaultAttr: {},
};
