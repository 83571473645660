import GloLayout from 'lowcode/common/GloLayout';
import EditorImg from 'lowcode/components/EditorImg';
import styles from './index.module.scss';
import EditorLink from 'lowcode/components/EditorLink';
import ClientComp from './ClientComp';

export default function Content({ id, data, initIndex = 0, isEditStatus = false }: any) {
    const { pc = '', h5 = '' } = data;

    return (
        <GloLayout id={id} className={`temp-banner-youtube temp-hide ${styles.content}`}>
            <div className="banner-youtube-item">
                <div className="banner-youtube-img">
                    <EditorImg pc={pc} h5={h5} />
                </div>
                <ClientComp id={id} isEditStatus={isEditStatus} data={data} />
            </div>
        </GloLayout>
    );
}
