// 首屏banner
export { default as BannerYoutube } from 'lowcode/plugins2/banner-youtube/indexS';

// 纯文本
export { default as TextRichtext } from 'lowcode/plugins2/text-richtext/indexS';
export { default as TextTextarea } from 'lowcode/plugins2/text-textarea/indexS';
export { default as TextTitle } from 'lowcode/plugins2/text-title/indexS';
// export { default as TextFaq } from 'lowcode/plugins2/text-faq/indexS';
// 纯资源
export { default as ResourceImgVideo } from 'lowcode/plugins2/resource-img-video/indexS';

// 活动
export { default as ActivityCard } from 'lowcode/plugins2/activity-card/indexS';

// 产品场景
export { default as ProductAccessories } from 'lowcode/plugins2/product-accessories/indexS';
export { default as ProductNavCommon } from 'lowcode/plugins2/product-nav-common/indexS';
export { default as ProductSceneA } from 'lowcode/plugins2/product-scene-a/indexS';
export { default as productWhatbox } from 'lowcode/plugins2/product-whatbox/indexS';
export { default as SwiperListA } from 'lowcode/plugins2/swiper-list-a/indexS';

// 锁屏
export { default as StickyOneA } from 'lowcode/plugins2/sticky-one-a/indexS';

// tabs 切换
export { default as TabSignResource } from 'lowcode/plugins2/tab-sign-resource/indexS';

// 其它
export { default as FormCommonV1 } from 'lowcode/plugins2/form-common-v1/indexS';
export { default as NavIcon } from 'lowcode/plugins2/nav-icon/indexS';

// 产品页通用
export { default as BundlesCommonV1 } from 'lowcode/plugins2/bundles-common-v1/indexS';
export { default as ShopifyColOne } from 'lowcode/plugins2/shopify-col-one/indexS';
export { default as ShopifyColThree } from 'lowcode/plugins2/shopify-col-three/indexS';
export { default as ShopifyColTwo } from 'lowcode/plugins2/shopify-col-two/indexS';
export { default as VideoListV1 } from 'lowcode/plugins2/video-list-v1/indexS';

