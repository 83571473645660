'use client';

import { useEffect, useState } from 'react';
import EditorImg from 'lowcode/components/EditorImg';
import EditorLink from 'lowcode/components/EditorLink';
import { shopifyCardText } from 'lowcode/common/shopify/shopifyCardText';
import { creditsDayListProducts } from './apis';
import getLocal from '@/app/utils/getLocal';

export default function ClientComp() {
    const { locale } = getLocal();
    const textA = shopifyCardText[locale] || shopifyCardText['us'];
    const [listData, setListData] = useState([]);
    // 请求单个
    const initFn = async () => {
        const data1 = await creditsDayListProducts(locale);

        if (data1.code === '0') {
            setListData(data1.data);
        }
    };
    useEffect(() => {
        initFn();
    }, []);
    return (
        <>
            {listData.map((item: any, index: number) => {
                return (
                    <div className="shopify-card-item" key={index}>
                        <div className="shopify-card-item-l">
                            <EditorImg pc={item.imageUrl} />
                            <div className="shopify-save">%{textA.save_off}</div>
                        </div>
                        <div className="shopify-card-item-r">
                            <div className="shopify-card-item-title">{item.title}</div>
                            <div className="shopify-card-item-subtitle1">{item.subtitle1}</div>
                            <div className="shopify-card-item-price1">
                                <span>{item.price}</span>
                                <span className="shopify-member-only">
                                    <span>{textA.member_only}</span>
                                </span>
                            </div>
                            <div className="shopify-card-item-price2">
                                <span>{item.price}</span>
                                <span>{textA.non_member_price}</span>
                                <span>{item.compareAtPrice}</span>
                            </div>
                            <div className="shopify-card-item-btns">
                                <EditorLink className="shopify-buy-now">{textA.buy_now}</EditorLink>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
}
