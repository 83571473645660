'use cilent';
const shopifyCardText: any = {
    us: {
        shopify_card_price_text1: '通常価格：',
        shopify_card_price_text2: '（税込）',
        btn_text1: 'さらに詳しく',
        btn_text2: '今すぐ購入',
    },
};

export const imgSettingConfig: any = {
    '1761950230755831809': 'https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709102425651/D2%2B220W.png',
    '1761950231011684353': 'https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709102459448/D2M%2B220W.png',
    '1761950231326257153': 'https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709102498254/D2M%2BEB%2B400W.png',
    '1761950231527583746': 'https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709102518603/DP%2BEB%2B400W.png',
};

export default shopifyCardText;
