'use client';
import EditorLink from 'lowcode/components/EditorLink';
import { useState } from 'react';

export default function Content({ id, isEditStatus, data }: any) {
    const { barA = {}, productObj = {}, list = [] } = data;
    const [h5Active, setH5Active] = useState(false);
    // 左侧点击显隐
    const toggleLHandle = () => {
        const h5Active2 = h5Active;
        setH5Active(!h5Active2);
    };
    return (
        <>
            <div className="product-nav-common-bara">
                <div className="product-nav-common-in">
                    <span className="product-nav-common-bara-l">{barA?.textL}</span>
                    <span className="product-nav-common-bara-r">{barA?.textR}</span>
                </div>
            </div>
            <div className={`product-nav-common-nav`}>
                <div className={`product-nav-common-nav-in${h5Active ? ' product-nav-common-l-active' : ''}`}>
                    <div className="product-nav-common-nav-l">
                        <span className="product-nav-common-name1" onClick={toggleLHandle}>
                            {productObj?.productName}
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                <path
                                    d="M10.1543 4.80762L6.1543 8.80762L2.1543 4.80762"
                                    stroke="white"
                                    strokeWidth="1.05"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                            </svg>
                        </span>
                        {productObj?.hasProductB && (
                            <div className="product-nav-common-others">
                                <span className="product-nav-common-name2">
                                    <EditorLink href={productObj?.productBLink}>{productObj?.productBName}</EditorLink>
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="product-nav-common-nav-c">
                        {list.map((item: any, index: number) => {
                            return (
                                <EditorLink key={index} href={item.href} className={`${index === 0 ? 'product-nav-common-active' : ''}`}>
                                    {item.name}
                                </EditorLink>
                            );
                        })}
                    </div>
                    <div className="product-nav-common-nav-r">
                        <EditorLink href={productObj?.buyLink} className="product-nav-common-nav-buy">
                            {productObj?.buyText}
                        </EditorLink>
                    </div>
                </div>
            </div>
        </>
    );
}
