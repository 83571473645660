'use client';

import { shopifyCardText } from '../utils/shopifyCardText';
import getLocal from '@/app/utils/getLocal';

export default function ShopifySellProcess({ num = 1, total = 1 }: { num?: number; total: number }) {
    const { locale } = getLocal();
    const textA = shopifyCardText[locale] || shopifyCardText['us'];
    const widthAll = 255;
    const currentW = widthAll * (num / total);
    return (
        <div className="shopify-sell-process">
            <div
                className="shopify-sell-process-in"
                style={{
                    width: `${currentW}px`,
                }}
            >
                <div className="shopify-sell-process-text">&nbsp;</div>
                <div className="shopify-sell-process-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                        <circle cx="12.8788" cy="12.5" r="12.064" fill="white" stroke="#EE911B" strokeWidth="0.872093" />
                        <path
                            d="M18.9835 10.0178L14.0375 10.4848L14.7796 1.74414L6.77417 15.8276L11.5039 15.1987L10.4574 23.2558L18.9835 10.0178Z"
                            fill="url(#paint0_linear_233_707)"
                        />
                        <defs>
                            <linearGradient id="paint0_linear_233_707" x1="19.2226" y1="28.5404" x2="2.05574" y2="28.1798" gradientUnits="userSpaceOnUse">
                                <stop offset="0.0161916" stopColor="#EC8E1B" />
                                <stop offset="0.491691" stopColor="#FBA11A" />
                                <stop offset="0.892701" stopColor="#FED03C" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <div className="shopify-sell-process-text-b">
                        {textA.sell_process_l}
                        {num}
                        {textA.sell_process_r}
                    </div>
                </div>
            </div>
        </div>
    );
}
