import { getListA } from 'lowcode/utils/shopify/getListA';
import ContentIn from './index';

export default async function Content({ id, data = {}, attr = {} }: any) {
    const list1 = await getListA();
    const listA = list1.nodes || [];

    return (
        <>
            <ContentIn id={id} data={data} attr={attr} listA={listA} />
        </>
    );
}
