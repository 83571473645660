'use client';
import { useState } from 'react';
import FormErrorTips from '../comps/FormErrorTips';

const emailRegex = /^[A-Za-z\d]+([.%~`$^*&(){}<>?"+=!_\\-]*[A-Za-z\d-])*@[a-zA-Z\d-]{1,30}\.[a-zA-Z\d.%#~`$^*&(){}<>?"+=!_\\-]{1,30}$/;
export default function FormEmail({ id, data, index, callback }: any) {
    const [showError, setShowError] = useState(false);
    const [tipsData, setTipsData] = useState({
        emptyTips: data.emptyTips || '',
    });
    const changeHandle = (e: any) => {
        const val = e.target.value;
        callback(data, index, val);
        if (data.isRequire && !val) {
            setShowError(true);
            setTipsData({
                emptyTips: data.emptyTips,
            });
        } else if (val && !emailRegex.test(val)) {
            setShowError(true);
            setTipsData({
                emptyTips: 'Email is invalid.',
            });
        } else {
            setShowError(false);
        }
    };
    return (
        <div className={`form-common-content ${showError ? ' form-common-show-error' : ''}`}>
            <input className="form-common-input" placeholder={data.placeholder} value={data.itemVal} onChange={changeHandle} maxLength={data.maxLen || 200} />
            <FormErrorTips data={tipsData} />
        </div>
    );
}
