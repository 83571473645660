import GloLayout from 'lowcode/common/GloLayout';
import styles from './index.module.scss';
import ClientComp from './ClientComp';

export default function Content({ id, data, initIndex = 0, isEditStatus = false }: any) {
    const { theme = '', title = '', list = [] } = data;

    return (
        <GloLayout id={id} className={`temp-product-accessories product-accessories-theme-${theme} temp-hide ${styles.content}`}>
            {title && <div className="product-accessories-title">{title}</div>}
            <ClientComp data={data} />
        </GloLayout>
    );
}
