'use client';
import EditorVideoDialog from 'lowcode/components/EditorVideoDialog';
import { useEffect, useRef, useState } from 'react';

export default function Content({ id, data, initIndex = 0, isEditStatus = false }: any) {
    const {
        theme = 'white',

        title = '',
        desc = '',

        btnLink1 = '',
        btnText1 = '',

        isVideo = false,
        pc = '',
        h5 = '',
    } = data;
    const [isShowVideo, setIsShowVideo] = useState(false);
    const [isEnter, setIsEnter] = useState(isEditStatus);
    const isEnterRef = useRef(isEditStatus);
    useEffect(() => {
        const elem = document.getElementById(`${id}`);
        if (elem) {
            const isEnterFn = () => {
                const clientH = document.documentElement.clientHeight;
                const { top } = elem.getBoundingClientRect();
                const hEnter = top < clientH - 150; //距离已进入

                if (!isEnterRef.current && hEnter) {
                    isEnterRef.current = true;
                    setIsEnter(true);
                } else if (isEnterRef.current && !hEnter) {
                    isEnterRef.current = false;
                    setIsEnter(false);
                }
            };
            isEnterFn(); //执行1次
            window.addEventListener('scroll', isEnterFn);
        }
    }, []);
    return (
        <div className={`banner-youtube-msg${isEnter ? ' isEnter' : ''}`}>
            <div className="bh-content">
                <h2 className="banner-youtube-title1">{title}</h2>
                <h3 className="banner-youtube-title2">{desc}</h3>
                <div className="banner-youtube-btns">
                    {btnLink1 && btnText1 && (
                        <button
                            className="banner-youtube-btn"
                            onClick={() => {
                                setIsShowVideo(true);
                            }}
                        >
                            {btnText1}
                        </button>
                    )}
                </div>
            </div>
            {isShowVideo && (
                <EditorVideoDialog
                    src={btnLink1}
                    callback={() => {
                        setIsShowVideo(false);
                    }}
                />
            )}
        </div>
    );
}
