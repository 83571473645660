'use client';
import EditorImg from 'lowcode/components/EditorImg';
import EditorLink from 'lowcode/components/EditorLink';
import { fetchGet } from 'lowcode/utils/service/FetchClient';
import { baseUrl } from 'lowcode/utils/service/baseUrl';
import { useEffect, useState } from 'react';
import { CompText } from './CompText';
import getLocal from '@/app/utils/getLocal';
export default function ClientComp({ id, data, initIndex = 0, isEditStatus = false }: any) {
    const { locale } = getLocal();
    const { title, list = [], isShowCustomize = false, titleC1 = '', titleC2 = '', pcC = '', moreTextC = '', moreLinkC = '' } = data;
    const [listA, setListA] = useState<any>(list);
    const textObj = CompText[locale] || CompText['us'];
    // 请求单个
    const initItemFn = async (item: any) => {
        const data1 = await fetchGet(`${baseUrl()}/ProductCaculation/productInfo?productId=${item.productId}&variantId=${item.variantId}`, 'us');

        if (data1.code === '0') {
            return data1.data || {};
        }
        return {};
    };
    useEffect(() => {
        // 初始化所有参数
        const initFn = async () => {
            const listB: any = JSON.parse(JSON.stringify(listA));
            for (let i = 0; i < list.length; i++) {
                const item = listB[i];
                const data1 = await initItemFn(list[i]);
                let data2 = null;

                if (item.hasVariantA && item.productIdA && item.variantIdA) {
                    data2 = await initItemFn({
                        productId: item.productIdA,
                        variantId: item.variantIdA,
                    });
                }

                item.price = data1.price;
                item.total1 = data1.price;
                item.total2 = data1.price;
                item.sub_count = 0;
                item.link_buy = `https://${locale}.ecoflow.com/cart/${item.productId}:1?channel=buy_button`;
                if (data2) {
                    item.sub_price1 = data2.price; //现价
                    item.sub_price2 = data2.compareAtPrice; //对比价
                }
            }

            setListA(listB);
        };
        initFn();
    }, []);

    // 添加
    const addHandle = (item: any, index: number) => {
        if (item.sub_count >= item.maxA) {
            return;
        }
        const sub_count = parseInt(item.sub_count) + 1;

        setItemForList(index, sub_count);
    };
    // 减少
    const redHandle = (item: any, index: number) => {
        if (item.sub_count <= 0) {
            return;
        }
        const sub_count = parseInt(item.sub_count) - 1;

        setItemForList(index, sub_count);
    };
    // 值输入
    const changeHandle = (item: any, index: number, e: any) => {
        const val = e?.target?.value;
        let num = parseInt(val);

        if (num < 0) {
            num = 0;
        } else if (num > item.sub_count) {
            num = item.maxA;
        }
        setItemForList(index, num);
    };
    // 执行变化
    const setItemForList = (index1: number, newVal: number) => {
        const listB: any = JSON.parse(JSON.stringify(listA));
        listB.forEach((item: any, index2: number) => {
            if (index1 === index2) {
                item.sub_count = newVal;
                item.total1 = item.price + item.sub_price1 * newVal;
                item.total2 = item.price + item.sub_price2 * newVal;
                item.link_buy = `https://${locale}.ecoflow.com/cart/${item.variantId}:1,${`${item.variantIdA}:${newVal}`}?channel=buy_button`;
            }
        });
        setListA(listB);
    };

    return (
        <div className="bundles-common-v1-in">
            {title && <div className="bundles-common-v1-main-title">{title}</div>}
            <div className={`bundles-common-v1-list`}>
                {listA.map((item: any, index: number) => {
                    return (
                        <div key={index} className="bundles-common-v1-card">
                            <div className="bundles-common-v1-card-title">{item.title}</div>
                            <div className="bundles-common-v1-card-img">
                                <EditorImg pc={item.pc} />
                            </div>
                            <div className="bundles-common-v1-card-desc1">{item.desc1}</div>
                            <div className="bundles-common-v1-card-desc2">{item.desc2}</div>
                            <div className="bundles-common-v1-card-desc3">{item.desc3}</div>
                            {item.hasVariantA && (
                                <div className="bundles-common-v1-variant-box">
                                    <div className="bundles-common-v1-variant-h">{item.titleA1}</div>
                                    <div className="bundles-common-v1-variant-c">
                                        <div className="bundles-common-v1-variant-l">
                                            <EditorImg pc={item.pcA} />
                                        </div>
                                        <div className="bundles-common-v1-variant-r">
                                            <div className="bundles-common-v1-variant-t1">{item.titleA2}</div>
                                            <div className="bundles-common-v1-variant-price">
                                                <span className="bundles-common-v1-variant-price-discount">{item.sub_price1}</span>
                                                <span className="bundles-common-v1-variant-price-original">{item.sub_price2}</span>
                                            </div>
                                            <div className="bundles-common-v1-variant-f">
                                                <div className="bundles-common-v1-variant-save">
                                                    {textObj.save} ${item.sub_price2 - item.sub_price1}
                                                </div>
                                                <div className="bundles-common-v1-calculator">
                                                    <span
                                                        className="bundles-common-v1-calculator-btn"
                                                        onClick={() => {
                                                            redHandle(item, index);
                                                        }}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <g className="svg-g">
                                                                <rect width="24" height="24" rx="12" fill="#38383D"></rect>
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M8.24129 12.545H15.7516C15.9291 12.545 16.0749 12.4939 16.1889 12.3916C16.303 12.2893 16.3601 12.1586 16.3601 11.9996C16.3601 11.8405 16.303 11.7098 16.1889 11.6075C16.0749 11.5052 15.9291 11.4541 15.7516 11.4541H8.24129C8.06381 11.4541 7.91804 11.5052 7.80395 11.6075C7.68986 11.7098 7.63281 11.8405 7.63281 11.9996C7.63281 12.1586 7.68986 12.2893 7.80395 12.3916C7.91804 12.4939 8.06381 12.545 8.24129 12.545Z"
                                                                    fill="#E6E6E6"
                                                                ></path>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="bundles-common-v1-calculator-number"
                                                        value={item.sub_count}
                                                        onChange={(e: any) => {
                                                            changeHandle(item, index, e);
                                                        }}
                                                    />
                                                    <span
                                                        className="bundles-common-v1-calculator-btn"
                                                        onClick={() => {
                                                            addHandle(item, index);
                                                        }}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fill="none">
                                                            <g className="svg-g">
                                                                <rect width="100%" height="100%" rx="12" fill="#38383D"></rect>
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M8.24129 12.6088H11.388V15.7555C11.388 15.933 11.445 16.0788 11.5591 16.1929C11.6732 16.3069 11.819 16.364 11.9964 16.364C12.1739 16.364 12.3197 16.3069 12.4338 16.1929C12.5479 16.0788 12.6049 15.933 12.6049 15.7555V12.6088H15.7516C15.9291 12.6088 16.0749 12.5518 16.1889 12.4377C16.303 12.3236 16.3601 12.1778 16.3601 12.0004C16.3601 11.8229 16.303 11.6771 16.1889 11.563C16.0749 11.4489 15.9291 11.3919 15.7516 11.3919H12.6049V8.24519C12.6049 8.06772 12.5479 7.92194 12.4338 7.80785C12.3197 7.69376 12.1739 7.63672 11.9964 7.63672C11.819 7.63672 11.6732 7.69376 11.5591 7.80785C11.445 7.92194 11.388 8.06772 11.388 8.24519V11.3919H8.24129C8.06381 11.3919 7.91804 11.4489 7.80395 11.563C7.68986 11.6771 7.63281 11.8229 7.63281 12.0004C7.63281 12.1778 7.68986 12.3236 7.80395 12.4377C7.91804 12.5518 8.06381 12.6088 8.24129 12.6088Z"
                                                                    fill="#E6E6E6"
                                                                ></path>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="bundles-common-v1-price-box">
                                <div className="bundles-common-v1-card-price">
                                    <span className="bundles-common-v1-card-price-discount">{item.total1}</span>
                                    {item.total1 !== item.total2 && <span className="bundles-common-v1-card-price-original">{item.total2}</span>}
                                </div>
                                {item.priceTips && <div className="bundles-common-v1-card-price-tips">{item.priceTips}</div>}
                                <EditorLink href={item.link_buy} className="bundles-common-v1-buy-btn">
                                    {textObj.buy}
                                </EditorLink>
                            </div>
                        </div>
                    );
                })}
                {isShowCustomize && (
                    <div className="bundles-common-v1-card bundles-common-v1-customize">
                        <div className="bundles-common-v1-customize-in">
                            <div className="bundles-common-v1-customize-titleC1">{titleC1}</div>
                            <div className="bundles-common-v1-customize-titleC2">{titleC2}</div>
                            {moreLinkC && moreTextC && (
                                <div className="bundles-common-v1-customize-btns">
                                    <EditorLink href={moreLinkC}>
                                        {moreTextC}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path
                                                d="M6.25651 2.35901L11.5898 7.69234L6.25651 13.0257"
                                                stroke="#3F68E0"
                                                strokeWidth="1.4"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            ></path>
                                        </svg>
                                    </EditorLink>
                                </div>
                            )}
                            <EditorImg pc={pcC} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
