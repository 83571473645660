import GloLayout from 'lowcode/common/GloLayout';
import styles from './index.module.scss';
import EditorImg from 'lowcode/components/EditorImg';

export default function Content({ id, data, initIndex = 0, isEditStatus = false }: any) {
    const { theme = '', title = '', subTitle = '', list = [] } = data;
    const itemF = list[0] || {};
    const listB = list.slice(1);
    return (
        <GloLayout id={id} className={`temp-product-whatbox temp-hide product-whatbox-${theme} ${styles.content}`}>
            <div className="product-whatbox-in">
                {title && <div className="product-whatbox-title">{title}</div>}
                {subTitle && <div className="product-whatbox-subTitle">{subTitle}</div>}
                <div className="product-whatbox-list">
                    <div className="product-whatbox-l">
                        <div className="product-whatbox-card">
                            <div className="product-whatbox-card-title">{itemF.title}</div>
                            <div className="product-whatbox-card-img">
                                <EditorImg pc={itemF.pc} />
                            </div>
                        </div>
                    </div>
                    <div className={`product-whatbox-r product-whatbox-len-${listB.length}`}>
                        {listB.map((item: any, index: number) => {
                            return (
                                <div key={index} className="product-whatbox-card">
                                    <div className="product-whatbox-card-title">{item.title}</div>
                                    <div className="product-whatbox-card-img">
                                        <EditorImg pc={item.pc} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </GloLayout>
    );
}
