import GloLayout from 'lowcode/common/GloLayout';
import styles from './index.module.scss';
import EditorLink from 'lowcode/components/EditorLink';
import ClientComp from './ClientComp';

export default function Content({ id, data, initIndex = 0, isEditStatus = false }: any) {
    const { theme = '', title, moreLink, moreText } = data;

    return (
        <GloLayout id={id} className={`temp-swiper-list-a ${styles.content} swiper-list-a-theme-${theme}`}>
            {title && <h3 className="swiper-list-a-title">{title}</h3>}
            <ClientComp data={data} />
        </GloLayout>
    );
}
