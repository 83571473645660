import GloLayout from 'lowcode/common/GloLayout';
import styles from './index.module.scss';
import ClientComp from './comps/ClientComp';

export default function Content({ id, data, initIndex = 0, isEditStatus = false }: any) {
    const { module } = data;

    return (
        <GloLayout id={id} className={`temp-shopify-card-three temp-hide ${styles.content}`}>
            <div className="shopify-card-in">
                <ClientComp module={module} />
            </div>
        </GloLayout>
    );
}
