'use client';
import DefaultStyle from 'lowcode/common/DefaultStyle';
import EditorImgVideo from 'lowcode/components/EditorImgVideo';
import EditorLink from 'lowcode/components/EditorLink';
import { FC } from 'react';
import { DEFAULT_DATA } from '../config';
import styles from './index.module.scss';

const Content: FC<{ id: string; attr: any; data: any }> = ({ id, attr, data = DEFAULT_DATA }) => {
    const { theme, position, title, content, btn, isVideo, pc, h5, link } = data;
    return (
        <section
            className={`${styles.content} temp-OurStory temp-OurStory-theme-${theme} temp-hide temp-${id}`}
            id={id}
            style={{
                color: theme,
            }}
        >
            <DefaultStyle id={id} attr={attr} />
            <div className={`OurStory-box OurStory-pos-${position}`}>
                <div className="OurStory-img">
                    <EditorImgVideo loop={true} autoPlay={true} isVideo={isVideo} pc={pc} h5={h5 || pc} alt={title} />
                </div>
                <article className="content-box">
                    <div className="content-box-in">
                        {title && <h2 className="title">{title}</h2>}
                        {content && <p className="content">{content}</p>}
                        {btn && link && (
                            <EditorLink
                                href={link}
                                className="btn"
                                rel="noreferrer"
                                style={{
                                    backgroundColor: theme,
                                }}
                                dataLayer={{
                                    event_name: 'learn_more',
                                    page_name: link,
                                    list_name: title,
                                    button_name: btn,
                                }}
                            >
                                {btn}
                            </EditorLink>
                        )}
                    </div>
                </article>
            </div>
        </section>
    );
};

export default Content;
