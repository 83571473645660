'use cilent';
const i18nMap: any = {
    us: 'en-us',
    ca: 'en-ca',
    uk: 'en-uk',
    eu: 'en-eu',
    za: 'en-za',
    au: 'en-au',
    de: 'de-de',
    fr: 'fr-fr',
    es: 'es-es',
    it: 'it-it',
    jp: 'ja-jp',
    kr: 'ko-kr',
    cn: 'zh-cn',
    ru: 'ru-ru',
    cis: 'ru-ru',
    nl: 'nl-nl',
    tw: 'zh-tw',
};

export default i18nMap;
