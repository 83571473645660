'use client';
import { useState, useEffect } from 'react';

export default function Content({ id, data }: any) {
    const { list = [] } = data;
    const [activeId, setActiveId] = useState('fd3c0705');

    useEffect(() => {
        const initFn = () => {
            for (let i = list.length - 1; i >= 0; i--) {
                initItemFn(list[i]);
            }
        };
        const initItemFn = (item: any) => {
            const elem = document.querySelector(`.temp-${item?.href}`);
            if (elem) {
                //const clientH = document.documentElement.clientHeight;
                const { top } = elem.getBoundingClientRect();
                const hEnterA = top < 200; //距离顶部200
                const hEnterB = top > -100; //已淡出-200
                if (hEnterA && hEnterB) {
                    setActiveId(item.href);
                }
            }
        };
        //initFn(); //执行1次
        window.addEventListener('scroll', initFn);
    }, []);

    return (
        <div className="bar-anchor-in">
            {list.map((item: any, index: number) => {
                return (
                    <a
                        className={`bar-anchor-item ${activeId === item.href ? 'bar-anchor-item-hover' : ''} bar-anchor-${id}-${item.href}`}
                        key={index}
                        href={`#${item.href}`}
                    >
                        {item.title}
                    </a>
                );
            })}
        </div>
    );
}
