'use client';
import { useState } from 'react';
import { textLang } from './textLang';
import getLocal from '@/app/utils/getLocal';

export default function FormPrivacy({ callback }: any) {
    const { locale } = getLocal();
    const textObj = textLang[locale] || textLang['us'];
    const [isActive, setIsActive] = useState(true);
    const toggleHandle = () => {
        const isActive2 = !isActive;
        setIsActive(isActive2);
        callback && callback(isActive2);
    };
    return (
        <div className={`form-common-privacy${isActive ? ' form-common-privacy-active' : ''}`} onClick={toggleHandle}>
            <span className="form-common-privacy-r">
                <span>{textObj.line1}</span>
                <a href="https://account.ecoflow.com/agreement/en-us/PrivacyPolicy.html">{textObj.line2}</a>
            </span>
        </div>
    );
}
