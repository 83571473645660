'use client';
import EditorLink from 'lowcode/components/EditorLink';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/scss';
import EditorImgVideo from 'lowcode/components/EditorImgVideo';

export default function List({ list = [], title }: any) {
    return (
        <div className="JoinUs-in JoinUs-h5">
            <Swiper
                className={`swiper-box`}
                modules={[Pagination]}
                spaceBetween={0}
                pagination={list?.length > 1}
                loop={true}
                slidesPerView={'auto'}
                initialSlide={0}
                centeredSlides={true}
            >
                {list?.map((item: any, index: number) => {
                    const { title: blog_title, desc, link, linkText } = item;

                    return (
                        <SwiperSlide className={`swiper-item item-${index + 1}`} key={index}>
                            <div className={`JoinUs-item`}>
                                <div className="JoinUs-item-img">
                                    <EditorImgVideo isVideo={item.isVideo} pc={item.pc} autoPlay={true} loop={true} />
                                </div>
                                <div className="JoinUs-item-title">{blog_title}</div>
                                <div className="JoinUs-item-box">
                                    <div className="JoinUs-item-desc">{desc}</div>
                                    {link && linkText && (
                                        <EditorLink
                                            href={link}
                                            className="JoinUs-item-link"
                                            dataLayer={{
                                                event_name: 'select_blog',
                                                page_name: link,
                                                list_name: title,
                                                blog_name: blog_title,
                                                button_name: linkText,
                                            }}
                                        >
                                            {linkText}
                                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <path
                                                        id="Path-2"
                                                        d="M5.08317 2.13321L9.4165 6.46654L5.08317 10.7999"
                                                        stroke="#3F68E0"
                                                        strokeWidth="1.1375"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </g>
                                            </svg>
                                        </EditorLink>
                                    )}
                                </div>
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
}
