'use client';
import { useState } from 'react';
import IconCheckbox from '../IconCheckbox';
import { textLang } from './textLang';
import getLocal from '@/app/utils/getLocal';

export default function FormSubscribe({ callback }: any) {
    const { locale } = getLocal();
    const textObj = textLang[locale] || textLang['us'];
    const [isActive, setIsActive] = useState(true);
    const toggleHandle = () => {
        const isActive2 = !isActive;
        setIsActive(isActive2);
        callback && callback(isActive2);
    };
    return (
        <div className={`form-common-subscribe${isActive ? ' form-common-subscribe-active' : ''}`} onClick={toggleHandle}>
            <span className="form-common-subscribe-l">
                <IconCheckbox />
            </span>
            <span className="form-common-subscribe-r">
                <span>{textObj.line1}</span>
            </span>
        </div>
    );
}
