//import GraphQLRequest from "lowcode/utils/shopify/GraphQLRequest";

export const getListA = async () => {
    // const { products }: any = await GraphQLRequest(`
	// 		{
	// 			products(first: 10) {
	// 				pageInfo {
	// 					hasNextPage
	// 					hasPreviousPage
	// 					startCursor
	// 					endCursor
	// 				}
	// 				nodes {
	// 					id
	// 					title
	// 					description
	// 					priceRange {
	// 						minVariantPrice {
	// 							amount
	// 							currencyCode
	// 						}
	// 						maxVariantPrice {
	// 							amount
	// 							currencyCode
	// 						}
	// 					}
	// 					images(first: 1) {
	// 						edges {
	// 							node {
	// 								transformedSrc(maxWidth: 400)
	// 							}
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 		`);
    return {
		"pageInfo": {
			"hasNextPage": false,
			"hasPreviousPage": false,
			"startCursor": "eyJsYXN0X2lkIjo3OTc5NjU2MzQ3OTI5LCJsYXN0X3ZhbHVlIjo3OTc5NjU2MzQ3OTI5fQ==",
			"endCursor": "eyJsYXN0X2lkIjo4NTEzNjQ0NjU4OTY5LCJsYXN0X3ZhbHVlIjo4NTEzNjQ0NjU4OTY5fQ=="
		},
		"nodes": [{
			"id": "gid://shopify/Product/7979656347929",
			"title": "Test",
			"description": "",
			"priceRange": {
				"minVariantPrice": {
					"amount": "1.0",
					"currencyCode": "CAD"
				},
				"maxVariantPrice": {
					"amount": "1.0",
					"currencyCode": "CAD"
				}
			},
			"images": {
				"edges": []
			}
		}, {
			"id": "gid://shopify/Product/7982444806425",
			"title": "O2O Test Product",
			"description": "",
			"priceRange": {
				"minVariantPrice": {
					"amount": "10.0",
					"currencyCode": "CAD"
				},
				"maxVariantPrice": {
					"amount": "10.0",
					"currencyCode": "CAD"
				}
			},
			"images": {
				"edges": [{
					"node": {
						"transformedSrc": "https://cdn.shopify.com/s/files/1/0674/1825/9737/products/ecoflow-us-ecoflow-river-2-max-portable-power-station-29922429763657_400x.png?v=1667556143"
					}
				}]
			}
		}, {
			"id": "gid://shopify/Product/7982458831129",
			"title": "O2O Test Product NOT Pick up",
			"description": "",
			"priceRange": {
				"minVariantPrice": {
					"amount": "10.0",
					"currencyCode": "CAD"
				},
				"maxVariantPrice": {
					"amount": "10.0",
					"currencyCode": "CAD"
				}
			},
			"images": {
				"edges": [{
					"node": {
						"transformedSrc": "https://cdn.shopify.com/s/files/1/0674/1825/9737/products/ecoflow-us-ecoflow-smart-generator-dual-fuel-29908745683017_400x.png?v=1667556431"
					}
				}]
			}
		}, {
			"id": "gid://shopify/Product/8234110681369",
			"title": "111",
			"description": "111",
			"priceRange": {
				"minVariantPrice": {
					"amount": "11.0",
					"currencyCode": "CAD"
				},
				"maxVariantPrice": {
					"amount": "11.0",
					"currencyCode": "CAD"
				}
			},
			"images": {
				"edges": [{
					"node": {
						"transformedSrc": "https://cdn.shopify.com/s/files/1/0674/1825/9737/products/af82955f0a03d9babf0db792ecba3660_400x.jpg?v=1682211735"
					}
				}]
			}
		}, {
			"id": "gid://shopify/Product/8234161996057",
			"title": "222",
			"description": "2222",
			"priceRange": {
				"minVariantPrice": {
					"amount": "1.0",
					"currencyCode": "CAD"
				},
				"maxVariantPrice": {
					"amount": "1.0",
					"currencyCode": "CAD"
				}
			},
			"images": {
				"edges": []
			}
		}, {
			"id": "gid://shopify/Product/8513607598361",
			"title": "假装是DPU膨胀券",
			"description": "",
			"priceRange": {
				"minVariantPrice": {
					"amount": "100.0",
					"currencyCode": "CAD"
				},
				"maxVariantPrice": {
					"amount": "100.0",
					"currencyCode": "CAD"
				}
			},
			"images": {
				"edges": []
			}
		}, {
			"id": "gid://shopify/Product/8513610416409",
			"title": "假装是DPU全款产品1",
			"description": "",
			"priceRange": {
				"minVariantPrice": {
					"amount": "10.0",
					"currencyCode": "CAD"
				},
				"maxVariantPrice": {
					"amount": "10.0",
					"currencyCode": "CAD"
				}
			},
			"images": {
				"edges": []
			}
		}, {
			"id": "gid://shopify/Product/8513611530521",
			"title": "假装是DPU全款产品2",
			"description": "",
			"priceRange": {
				"minVariantPrice": {
					"amount": "10.0",
					"currencyCode": "CAD"
				},
				"maxVariantPrice": {
					"amount": "10.0",
					"currencyCode": "CAD"
				}
			},
			"images": {
				"edges": []
			}
		}, {
			"id": "gid://shopify/Product/8513644658969",
			"title": "假装是DPU赠品KOL",
			"description": "",
			"priceRange": {
				"minVariantPrice": {
					"amount": "0.0",
					"currencyCode": "CAD"
				},
				"maxVariantPrice": {
					"amount": "0.0",
					"currencyCode": "CAD"
				}
			},
			"images": {
				"edges": []
			}
		}]
	};
};