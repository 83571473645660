'use client';
export type ShopifyCartItemType = {
    originalPrice?: number | string; // 原价
    discountPrice: number | string; // 折扣价
    img: string; // 图片
    productImg?: string;
    title: string; // 产品名称/标题
    productId: string; // 产品id
    variantId: string; // 变体id
    quantity?: number; // 购物车数量
    inventory?: number; // 库存数量
    parentVariantId?: string; // 附属产品之父级主产品变体id（见/cost-saving页）
};

export type ShopifyCartProps = {
    items: ShopifyCartItemType[];
    callback: (param: ShopifyCartItemType[], isClose?: boolean) => void;
};

export const currencySymbolLocalMap: any = {
    us: '$', // 前面
    ca: '$', // 前
    uk: '£', // 前
    eu: '€', // 前
    de: '€', // 后
    fr: '€', // 后
    it: '€', // 后
    es: '€', // 后
    cis: '₽', // 后
    jp: '¥', // 前
    cn: '¥', // 前
    kr: '₩', // 前
    sa: 'د.إ', // 前
    za: 'R', // 前
    au: '$', // 前
    tw: 'NT$', // 前
    hk: 'HK$', //前
    br: 'R$',
};

export const i18nMap: Record<string, string> = {
    us: 'en-us',
    ca: 'en-ca',
    uk: 'en-uk',
    eu: 'en-eu',
    za: 'en-za',
    au: 'en-au',
    de: 'de-de',
    fr: 'fr-fr',
    es: 'es-es',
    it: 'it-it',
    jp: 'ja-jp',
    kr: 'ko-kr',
    cn: 'zh-cn',
    ru: 'ru-ru',
    cis: 'ru-ru',
    nl: 'nl-nl',
    tw: 'zh-tw',
};

export const FormatMoneyLocalNoneAfter = (key: number | string, locale: string, splitStr = ',') => {
    let value: any = 0;
    const PERIOD_SITES: string[] = ['eu', 'de', 'fr', 'it', 'es'];
    if (key || key === 0) {
        const tmpKey = key.toString();
        const reg = /(\d)(?=(\d{3})+$)/g;
        // 欧德法意西 小数点用逗号[,]表示 其余用小数点[.]
        if (PERIOD_SITES.includes(locale)) {
            tmpKey = tmpKey.replace('.', ',');
        }
        value = tmpKey.replace(reg, `$1${splitStr !== ',' ? splitStr : PERIOD_SITES.includes(locale) ? '.' : splitStr}`);
    }
    const backSort: string[] = ['de', 'fr', 'it', 'es', 'cis'];
    return backSort?.includes(locale)
        ? value + (currencySymbolLocalMap[locale] || currencySymbolLocalMap.us)
        : (currencySymbolLocalMap[locale] || currencySymbolLocalMap.us) + value;
};

export const FormatMoneyLocal = (key: number | string, locale: string, splitStr = ',') => {
    let value: any = 0;
    const PERIOD_SITES: string[] = ['eu', 'de', 'fr', 'it', 'es'];
    const IS_PERIOD_SITES = PERIOD_SITES.includes(locale);
    const backSort: string[] = ['de', 'fr', 'it', 'es', 'cis'];
    if (key || key === 0) {
        const tmpKey = key.toString();
        // 欧德法意西 小数点用逗号[,]表示 其余用小数点[.]
        if (PERIOD_SITES.includes(locale)) {
            tmpKey = tmpKey.replace('.', ',');
        }
        const reg = /(\d)(?=(\d{3})+$)/g;
        value = tmpKey.replace(reg, `$1${splitStr !== ',' ? splitStr : IS_PERIOD_SITES ? '.' : splitStr}`);
    }

    let valueB = value;
    if (valueB.endsWith('.00')) {
        valueB = value.replace('.00', '');
    }
    // 加入符号
    const valueA = backSort?.includes(locale)
        ? valueB + (currencySymbolLocalMap[locale] || currencySymbolLocalMap.us)
        : (currencySymbolLocalMap[locale] || currencySymbolLocalMap.us) + valueB + (locale === 'jp' ? '（税込）' : '');

    return valueA;
};

/**
 * @param price 价格
 * @param locale
 * @returns str
 */
export const formatPrice = (price: string, locale: string): string => {
    const str = price ? String(price).replace('.00', '') : '0';
    let newStr = '';

    newStr = new Intl.NumberFormat(i18nMap[locale]).format(Number(str));

    if (isNaN(Number(str))) newStr = '0';
    if (locale === 'za') newStr = newStr.replace(/\W/g, ',');
    if (locale === 'de') newStr = `${newStr.replace(/\./g, ' ')} `;

    // 欧洲5国想要加空格
    if (['fr', 'de', 'es', 'it'].includes(locale)) return `${newStr} ${currencySymbolLocalMap[locale]}`;
    if (locale === 'eu') return `${currencySymbolLocalMap[locale]} ${newStr}`;
    return currencySymbolLocalMap[locale] + newStr;
};
