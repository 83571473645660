import GloLayout from 'lowcode/common/GloLayout';
import styles from './index.module.scss';
import ClientComp from './ClientComp';

export default function Content({ id, data, initIndex = 0, isEditStatus = false }: any) {
    return (
        <GloLayout id={id} className={`temp-product-nav-common temp-hide ${styles.content}`}>
            <ClientComp id={id} isEditStatus={isEditStatus} data={data} />
        </GloLayout>
    );
}
