'use cilent';
const shopifyCardText: any = {
    us: {
        shopify_card_price_text1: '通常価格：',
        shopify_card_price_text2: '（税込）',
        btn_text1: 'さらに詳しく',
        btn_text2: '今すぐ購入',
    },
};

export const imgSettingConfig: any = {
    // 第1组第1行
    '42337106002081': 'https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709103204155/D2.png',
    '43020979863713': 'https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709103315666/D2M.png',
    '43129277513889': 'https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709103859271/DP.png',
    // 第1组第2行
    '42629147295905': 'https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709103906831/R2.png',
    '42629150408865': 'https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709103932424/R2M.png',
    '43727378153633': 'https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709103951949/R2P.png',
    //第2组第1行
    '41282474836129': 'https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709104095059/400W.png',
    '41660573876385': 'https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709104119556/220W.png',
    '39255422009505': 'https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709104137683/160W.png',
    //第2组第2行
    '42399221612705': 'https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709104172742/D2EB.png',
    '43047063584929': 'https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709104197116/D2MEB.png',
    '41256639266977': 'https://ecoflow-service-us-cdn.ecoflow.com/configurable/images/1709104227262/DPEB.png',
};

export default shopifyCardText;
