import GloLayout from 'lowcode/common/GloLayout';
import styles from './index.module.scss';
import ClientStyle from './comp/ClientStyle';
import ClientComp from './comp/ClientComp';

export default function Content({ id, data, initIndex = 0, isEditStatus = false }: any) {
    const { theme = '', title, moreLink, moreText } = data;

    return (
        <GloLayout id={id} className={`temp-bar-anchor ${styles.content}`}>
            <ClientStyle id={id} data={data} />
            <ClientComp id={id} data={data} />
        </GloLayout>
    );
}
