'use client';
import { useState } from 'react';
import FormErrorTips from '../comps/FormErrorTips';

export default function FormTextarea({ id, data, index, callback }: any) {
    const [showError, setShowError] = useState(false);
    const changeHandle = (e: any) => {
        const val = e.target.value;
        callback(data, index, val);
        if (data.isRequire && !val) {
            setShowError(true);
        } else {
            setShowError(false);
        }
    };
    return (
        <div className={`form-common-content form-common-textarea ${showError ? ' form-common-show-error' : ''}`}>
            <textarea
                className="form-common-textarea-input"
                placeholder={data.placeholder}
                value={data.itemVal}
                onChange={changeHandle}
                maxLength={data.maxLen || 200}
            ></textarea>
            <div className="form-common-textarea-maxlen">
                {`${data.itemVal}`.length}/{data.maxLen || 200}
            </div>
            <FormErrorTips data={data} />
        </div>
    );
}
