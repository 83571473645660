'use client';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './index.module.scss';

export default function EditorVideoDialog({ className = '', src, callback }: any) {
    const [isShow, seIsShow] = useState(false);
    const [isSSR, setIsSSR] = useState<boolean>(true);

    useEffect(() => {
        setIsSSR(false);
    }, []);

    // 关闭
    const cloasHandle = () => {
        seIsShow(false);
        callback && callback(false);
    };
    if (!isSSR) {
        return ReactDOM.createPortal(
            <div className={`${styles.youtube} ${className} lowcode-youtube`}>
                <div className={`EditorDialog-dialog${isShow ? ' isShow' : ''}`}>
                    <div className="EditorDialog-body">
                        <iframe className="EditorDialog-iframe" src={src} />
                    </div>
                    <svg
                        className="EditorDialog-close"
                        onClick={cloasHandle}
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M256 48C140.559 48 48 140.559 48 256c0 115.436 92.559 208 208 208 115.435 0 208-92.564 208-208 0-115.441-92.564-208-208-208zm104.002 282.881l-29.12 29.117L256 285.117l-74.881 74.881-29.121-29.117L226.881 256l-74.883-74.881 29.121-29.116L256 226.881l74.881-74.878 29.12 29.116L285.119 256l74.883 74.881z"></path>
                    </svg>
                </div>
            </div>,
            (document as any).body,
        );
    }

    return <></>;
}
