'use client';

import EditorVideoDialog from 'lowcode/components/EditorVideoDialog';
import { useState } from 'react';

export default function ClientCompItem({ item }: any) {
    const [isShow, setIsShow] = useState(false);
    const clickHandle = () => {
        setIsShow(true);
    };
    return (
        <>
            <div className="video-list-v1-icon" onClick={clickHandle}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM28.0661 20.8524C28.7021 20.4621 28.7021 19.538 28.0661 19.1477L18.1781 13.0801C17.5118 12.6712 16.6551 13.1507 16.6551 13.9324V26.0677C16.6551 26.8495 17.5118 27.3289 18.1781 26.92L28.0661 20.8524Z"
                        fill="white"
                    ></path>
                </svg>
            </div>
            {isShow && (
                <EditorVideoDialog
                    src={item.link}
                    callback={() => {
                        setIsShow(false);
                    }}
                />
            )}
        </>
    );
}
