'use client';
import EditorImg from 'lowcode/components/EditorImg';
import EditorVideo from 'lowcode/components/EditorVideo';
import { useState } from 'react';

export default function Content({ id, initIndex, data }: any) {
    const { list = [] } = data;
    const [openTab, setOpenTab] = useState<number>(initIndex);

    // 切换事件
    const toggleHandle = (index: number) => {
        setOpenTab(index);
    };

    return (
        <div className="tab-sign-a-box">
            <div className="tab-sign-a-c">
                {list.map((item: any, index: number) => {
                    return (
                        <div className={`tab-sign-a-c-item${openTab === index ? ' tab-sign-a-active' : ''}`} key={index}>
                            {item.isVideo && (
                                <>
                                    <EditorVideo autoPlay={true} loop={true} pc={item.pc} />
                                </>
                            )}
                            {!item.isVideo && (
                                <>
                                    <EditorImg pc={item.pc} h5={item.h5} />
                                </>
                            )}
                        </div>
                    );
                })}
            </div>
            <div className="tab-sign-a-h">
                {list.map((item: any, index: number) => {
                    return (
                        <div
                            className={`tab-sign-a-h-item${openTab === index ? ' tab-sign-a-active' : ''}`}
                            onClick={() => {
                                toggleHandle(index);
                            }}
                            key={index}
                        >
                            {item.tabName}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
