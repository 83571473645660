'use client';
import { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import getLocal from '@/app/utils/getLocal';
import { rpcMap } from '@/constants/lang';

const SECRET_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_REGISTER_SECRET_KEY;

export default function FormRecaptcha({ callback }: any) {
    const { locale } = getLocal();
    const recaptchaRef = useRef();
    const hl = rpcMap[locale];
    const onReCAPTCHAChange = async (captchaCode: string) => {
        callback && callback(captchaCode);
    };

    return (
        <div className={`form-common-recaptcha`}>
            {/* @ts-ignore */}
            <ReCAPTCHA ref={recaptchaRef} hl={hl} sitekey={SECRET_KEY} onChange={onReCAPTCHAChange} />
        </div>
    );
}
