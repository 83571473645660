// 间隔
export { default as Space } from 'lowcode/plugins/space/Space/indexS';

// 全局bar-顶部
export { default as BarAnchor } from 'lowcode/plugins2/bar-anchor/indexS';

// 满屏图片
export { default as BannerSwiper } from 'lowcode/plugins/banner/BannerSwiper/indexS';

// 列表-产品推荐
export { default as SwiperCardB } from 'lowcode/plugins/list-recom/SwiperCardB/indexS';

// 列表-外部资源
export { default as ListNewsCardA } from 'lowcode/plugins/list-outer/ListNewsCardA/indexS';
export { default as ListNewsCardB } from 'lowcode/plugins/list-outer/ListNewsCardB/indexS';
export { default as ListYoutube } from 'lowcode/plugins/list-outer/ListYoutube/indexS';

// 邮箱订阅
export { default as SubscribeEmail } from 'lowcode/plugins/email/SubscribeEmail/indexS';

// 评论卡片
export { default as CommentCard } from 'lowcode/plugins/CommonProductCard/CommentCard/indexS';

// 其它
export { default as ShopifyGoodsA } from 'lowcode/plugins/shopifygoods/ShopifyGoodsA/indexS';
